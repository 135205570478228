import { IntlShape } from 'react-intl';

import {
  IItemOption,
  IItemOptionModifier,
  IItemOptionModifierWithQuantity,
  ISanityItemOptionModifier,
} from '@rbi-ctg/menu';
import { ClickEventComponentNames, CustomEventNames, EventTypes } from 'state/cdp/constants';
import { ICdpCtx } from 'state/cdp/types';

import { IModifierSelections, ModifierTypes } from './types';

export const getSingleSelectedOption = (selections: IModifierSelections) =>
  Object.values(selections)[0];

export const getModifierOptionMultiplier = (modifierOption: IItemOptionModifier) =>
  modifierOption.modifierMultiplier?.multiplier || modifierOption.multiplier || 0;

export const transformSelectedOptionToSelections = (
  selectedOption: IItemOptionModifier,
  enableMultiplierAsQuantity = false
) => {
  return {
    [selectedOption._key]: {
      ...selectedOption,
      quantity: enableMultiplierAsQuantity ? getModifierOptionMultiplier(selectedOption) : 1,
    },
  };
};

export const getModifierOptionName = (modifierOption: IItemOptionModifier) =>
  modifierOption.name?.locale || '';

export const getModifierNonNumericOptionLabel = (
  modifierOption: IItemOptionModifier,
  usePrefix: boolean
) =>
  usePrefix
    ? modifierOption.modifierMultiplier?.prefix?.locale
    : getModifierOptionMultiplier(modifierOption).toString();

export const getModifierImage = (modifier: IItemOption): string | undefined => {
  return getModifierOptionImage(modifier.options?.find(Boolean));
};

export const getModifierOptionImage = (modifierOption: IItemOptionModifier | undefined) => {
  const rawImage = (modifierOption as ISanityItemOptionModifier)?.image;
  return rawImage ? JSON.stringify(rawImage) : undefined;
};

const isCheckboxModifier = (modifier: IItemOption): boolean => {
  const hasBothOptions = modifier.options.every(
    opt => getModifierOptionMultiplier(opt) === 0 || getModifierOptionMultiplier(opt) === 1
  );
  return !modifier.singleChoiceOnly && modifier.options.length === 2 && hasBothOptions;
};

const isNonNumericModifier = (modifier: IItemOption): boolean => {
  return !modifier.singleChoiceOnly && !!modifier.displayModifierMultiplierName;
};

const isNumericModifier = (modifier: IItemOption): boolean => {
  return !modifier.singleChoiceOnly && !modifier.displayModifierMultiplierName;
};

const isSingleChoiceModifier = (modifier: IItemOption): boolean => {
  return !!modifier.singleChoiceOnly && modifier.maxAmount === 1;
};

const isMultipleChoiceModifier = (modifier: IItemOption): boolean => {
  return modifier.maxAmount > 1;
};

export const getModifierType = (modifier: IItemOption): ModifierTypes => {
  if (isMultipleChoiceModifier(modifier)) {
    return ModifierTypes.MULTIPLE_CHOICE;
  } else if (isCheckboxModifier(modifier)) {
    return ModifierTypes.CHECKBOX;
  } else if (isNonNumericModifier(modifier)) {
    return ModifierTypes.NON_NUMERIC;
  } else if (isNumericModifier(modifier)) {
    return ModifierTypes.NUMERIC;
  } else if (isSingleChoiceModifier(modifier)) {
    return ModifierTypes.SINGLE_CHOICE;
  }
  return ModifierTypes.NON_NUMERIC;
};

export const getModifierGroupName = (modifier: IItemOption): string => {
  const modifierType = getModifierType(modifier);
  if (
    ModifierTypes.SINGLE_CHOICE === modifierType ||
    ModifierTypes.MULTIPLE_CHOICE === modifierType
  ) {
    return modifier.name?.locale || '';
  }
  return modifier.displayGroup?.name?.locale || '';
};

export const computeModifierSelectionLabel = ({
  modifierType,
  modifierName,
  modifierOption,
  formatMessage,
}: {
  modifierType: ModifierTypes;
  modifierName: string;
  modifierOption: IItemOptionModifierWithQuantity;
  formatMessage: IntlShape['formatMessage'];
}): string => {
  switch (modifierType) {
    case ModifierTypes.CHECKBOX:
      return getModifierOptionMultiplier(modifierOption) === 0
        ? formatMessage({ id: 'menuModifierRemoveOption' }, { modifierName })
        : formatMessage({ id: 'menuModifierAddOption' }, { modifierName });
    case ModifierTypes.NON_NUMERIC:
      return `${modifierOption.modifierMultiplier?.prefix?.locale} ${modifierName}`;
    case ModifierTypes.NUMERIC:
      return `${getModifierOptionMultiplier(modifierOption)} ${modifierName}`;
    case ModifierTypes.SINGLE_CHOICE:
      return getModifierOptionName(modifierOption);
    case ModifierTypes.MULTIPLE_CHOICE:
      return `${modifierOption.quantity} ${getModifierOptionName(modifierOption)}`;
    default:
      return '';
  }
};

export const logProductModifierCustomization = (
  text: string,
  headerText: string,
  componentKey: string,
  cdp: ICdpCtx
) => {
  cdp.trackEvent({
    name: CustomEventNames.CLICK_EVENT,
    type: EventTypes.Navigation,
    attributes: {
      component: ClickEventComponentNames.PRODUCT_MODIFIER_CUSTOMIZATION,
      text,
      headerText,
      componentId: componentKey,
    },
  });
};
